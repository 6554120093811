import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class GuestGuardService implements CanActivate {

    constructor(
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (route.routeConfig.path === 'login' && localStorage.getItem('auth_token')) {
            this.router.navigateByUrl('application');
        } else {
            return true;
        } 
    }
}
