<!-- <mat-toolbar color="primary">
    <span style="flex: 0.13 1 auto;"></span>
    <img src="../../../../assets/img/logo-white.svg" height="50px">
    <span class="spacer"></span>
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="user icon">
        <mat-icon>account_circle</mat-icon>
    </button>
</mat-toolbar>

<mat-menu #menu="matMenu">
    <button mat-menu-item>
        <mat-icon>person</mat-icon>
        <span>My Profile</span>
    </button>
    <button mat-menu-item>
        <mat-icon>receipt_long</mat-icon>
        <span>Billing</span>
    </button>
    <button mat-menu-item (click)="signout()">
        <mat-icon>login</mat-icon>
        <span>Sign Out</span>
    </button>
</mat-menu> -->

<mat-toolbar color="primary">
    <img src="../../../../assets/img/logo-white.svg" height="50px">
    <span class="spacer"></span>
    <nav mat-tab-nav-bar mat-align-tabs="center" fxHide.xs fxHide.sm>
        <a style="color: white;" mat-tab-link (click)="changeTab('/application/all-devices')"
            [active]="checkActiveTab('/application/all-devices')"> All Devices </a>
        <a style="color: white;" mat-tab-link (click)="changeTab('/application/my-instances')"
            [active]="checkActiveTab('/application/my-instances')"> My Instances </a>
        <a style="color: white;" mat-tab-link (click)="changeTab('/application/my-devices')"
            [active]="checkActiveTab('/application/my-devices')"> My Devices </a>
    </nav>
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="user icon">
        <mat-icon>account_circle</mat-icon>
    </button>
</mat-toolbar>

<mat-menu #menu="matMenu">
    <button mat-menu-item>
        <mat-icon>person</mat-icon>
        <span>My Profile</span>
    </button>
    <button mat-menu-item>
        <mat-icon>receipt_long</mat-icon>
        <span>Billing</span>
    </button>
    <button mat-menu-item (click)="signout()">
        <mat-icon>login</mat-icon>
        <span>Sign Out</span>
    </button>
</mat-menu>

<nav mat-tab-nav-bar mat-align-tabs="center" [backgroundColor]="'primary'" [disablePagination]="true" fxHide fxShow.xs fxShow.sm style="margin-top: -1px;">
    <a mat-tab-link (click)="changeTab('/application/all-devices')"
        [active]="checkActiveTab('/application/all-devices')"> All Devices </a>
    <a mat-tab-link (click)="changeTab('/application/my-instances')"
        [active]="checkActiveTab('/application/my-instances')"> My Instances </a>
    <a mat-tab-link (click)="changeTab('/application/my-devices')"
        [active]="checkActiveTab('/application/my-devices')"> My Devices </a>
</nav>