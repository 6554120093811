<mat-card fxLayout="column" fxLayoutGap="8px" fxFill>
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <h3 class="mrg0"><b>Filters</b></h3>
    </div>

    <div class="mrg8-TB"><mat-divider></mat-divider></div>
    <section class="mrg8-T mrg16-B">
        <mat-checkbox [(ngModel)]="isAllDevices" (ngModelChange)="mainFilterSelectionChange()"><b>All Devices</b></mat-checkbox>
    </section>
    <div><mat-divider></mat-divider></div>
    <section fxLayout="column">
        <label><b>Location(miles)</b></label>
        <mat-slider [(ngModel)]="locationRadius" [disabled]="isAllDevices"
            [min]="10" [max]="100" [step]="10" [thumbLabel]="true"
            (change)="mainFilterSelectionChange()"
            class="width-100 mrg16-T"></mat-slider>
    </section>
    
    <!-- 
    <div><mat-divider></mat-divider></div>    
    <section>
        <label><b>Zip Code</b></label>
        <mat-form-field appearance="outline" class="width-100">
            <input matInput name="zipCode">
            <button mat-icon-button matSuffix color="primary" aria-label="search zip code">
                <mat-icon>search</mat-icon>
            </button>
        </mat-form-field>
    </section>
    -->
    <ng-container *ngFor="let filterGroup of filterGroups | keyvalue">
        <ng-container *ngIf="filterGroup.value.values.length > 0">
            <div><mat-divider></mat-divider></div>
            <section>
                <label class="mrg8-TB"><b>{{filterGroup.value.label}}</b></label>
                <div fxLayout="column">
                    <ng-container *ngFor="let filterValue of filterGroup.value.values">
                        <mat-checkbox [checked]="filterGroup.value.selectedValues"
                        (change)="filterSelectionChange(filterGroup.value.selectedValues, filterValue)">{{filterValue}}</mat-checkbox>
                    </ng-container>
                </div>
            </section>
        </ng-container>
    </ng-container>
</mat-card>