import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { HeaderComponent } from './components/header/header.component';
import { AuthGuardService } from './guards/auth.guard';
import { GuestGuardService } from './guards/guest.guard';
import { ToastrModule } from 'ngx-toastr';


@NgModule({
  declarations: [
    SideNavComponent,
    HeaderComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(), // ToastrModule added
    
  ],
  providers: [
    AuthGuardService,
    GuestGuardService
  ],
  exports: [SideNavComponent, HeaderComponent]
})
export class SharedModule { }
