import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public tabName = '/application/all-devices';

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.tabName = this.router.url;
  }

  checkActiveTab(tabName){
    return (this.router.url.includes(tabName)) ? true : false;
  }

  public changeTab(tabName){
    this.tabName = tabName;
    this.router.navigate([tabName]);
  }

  public signout() {
    localStorage.clear();
    this.router.navigate(['/home']);
  }

}
