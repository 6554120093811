import { Component, ContentChild, OnInit, TemplateRef, Input, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

  @ContentChild('sideNav', { read: TemplateRef, static: true }) sideNavTemplate;

  @Input() filterGroups = {};
  @Input() isFilterActive : boolean;
  @Input() isAllDevices : boolean;
  @Input() locationRadius : number = 30;
  @Output() onFilterSelectionChange = new EventEmitter();
  @Output() onMainFilterSelectionChange = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  filterSelectionChange(selectedvaluesArray, selectedValue) {
    if(selectedvaluesArray.includes(selectedValue)){
      selectedvaluesArray.splice(selectedvaluesArray.indexOf(selectedValue), 1);
    }else{
      selectedvaluesArray.push(selectedValue);
    }

    let emitValue = {
      "isAllDevices" : this.isAllDevices,
      "filterGroups" : this.filterGroups,
      "locationRadius" : this.locationRadius
    }

    this.onFilterSelectionChange.emit(emitValue);
  }

  mainFilterSelectionChange(){
    let emitValue = {
      "isAllDevices" : this.isAllDevices,
      "locationRadius" : this.locationRadius
    }
    this.onMainFilterSelectionChange.emit(emitValue);
  }

}
